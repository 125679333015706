import React, { Component } from 'react';
import posts from '../../../sample-posts';
import Nav from '../../../components/Nav/Nav';
import Box from '../../../components/Box/Box';
import Text from '../../../components/Text/Text';
import LinkAnchor from '../../../components/Links/LinkAnchor';
import Image from '../../../components/Image/Image';

class antiFragile extends Component {
    state = {
        posts: posts,
        isActive: true
    }

    render() {
        const {img} = this.state.posts.antiFragile;

        return (
            <React.Fragment>
                <Box pt={3} px={[2, 2, 5]} mb={5}>
                    <Box>
                        <Nav active={this.state.isActive} />
                    </Box>  
                    <Text
                        as="h1"
                        variant="body"
                        fontSize={6}
                        fontWeight="bold"
                        color="green.0"
                        my={4}
                    >
                        Antifragile UI/UX
                    </Text>
                    <Box measureWide>
                        <Text as="h2" variant="body" fontSize={4} fontWeight="bold" mb={2}>
                            Project overview
                        </Text>
                        <Text as="p" variant="body" style={{display: 'inline'}}>
                            <LinkAnchor variant="default" href="https://antifragile-ui-ux.now.sh/" target="blank">
                                Antifragile UI/UX
                            </LinkAnchor> is a platform focused on showing screen recordings of how digital products adapt and respond to errors in a user's flow. I used React with Styled-Components for all my front-end development, Amazon S3 to serve all my images, Wista to host my videos, and Now to host the site. I have the project up on <LinkAnchor variant="default" href="https://github.com/daninmotion/antifragile" target="blank">
                                Github
                            </LinkAnchor>.
                        </Text>
                        <Text as="p" variant="body" marginTop={2}>
                            As a designer creating user interfaces and experiences I look for inspiration in a few great products. I always find myself recording these experiences and taking notes. Instead of having them sit in a folder somewhere collecting dust, I thought I would create a platform where I can document and share them with others.
                        </Text>
                    </Box>
                    <Box mt={4}>
                        <Text 
                            as="h2" 
                            variant="body"
                            measureNarrow 
                            fontSize={4} 
                            fontWeight="bold"  
                            lineHeight="title" 
                            mb={2}
                        >
                            Designing the video page
                        </Text>
                        <Text as="p" variant="body" measureWide>
                            I started out with the meat of the product, the video page. I wanted something clear and simple. Here are a few designs I pulled inspiration from.
                        </Text>
                        <Text as="h3" variant="body" fontWeight="bold" fontSize={1} mt={3} mb={2}>
                            Inspiration-1
                        </Text>
                        <Box 
                            mt={1} 
                            maxWidth={9}
                        >
                            <Image 
                                src={img[0]} 
                                alt="Inspiration part one" 
                            />
                        </Box>
                        <Text as="h3" variant="body" fontWeight="bold" fontSize={1} mt={3} mb={2}>
                            Inspiration-2
                        </Text>
                        <Box 
                            mt={1} 
                            maxWidth={9}
                        >
                            <Image 
                                src={img[1]} 
                                alt="Inspiration part two" 
                            />
                        </Box>
                        <Text as="h3" variant="body" fontWeight="bold" fontSize={1} mt={3} mb={2}>
                            Inspiration-3
                        </Text>
                        <Box 
                            mt={1} 
                            maxWidth={9}
                        >
                            <Image 
                                src={img[1]} 
                                alt="Inspiration part three" 
                            />
                        </Box>
                        <Text as="h3" variant="body" fontWeight="bold" fontSize={1} mt={3} mb={2}>
                            Inspiration-4
                        </Text>
                        <Box 
                            mt={1} 
                            maxWidth={9}
                        >
                            <Image 
                                src={img[3]} 
                                alt="Inspiration part four" 
                            />
                        </Box>
                        <Text as="h3" variant="body" fontWeight="bold" fontSize={1} mt={3} mb={2}>
                            Inspiration-5
                        </Text>
                        <Box 
                            mt={1} 
                            maxWidth={9}
                        >
                            <Image 
                                src={img[4]} 
                                alt="Inspiration part four" 
                            />
                        </Box>
                        <Text as="p" variant="body" marginTop={2} measureWide>
                            I wanted to display the video and content in a less "YouTube" manner. Which is typically the video on the right-hand side and an aside with content. Nothing wrong with that of course but since this is a personal project I wanted to experiment a bit more with the layout.
                        </Text>
                        <Text as="p" variant="body" marginTop={2} measureWide>
                            The final design allowed the video to take the full width of the screen with a certain height restriction and the content right underneath. The content is separated into two sections. On the left, the actual videos with two navigation icons. The navigation icons are there to let the user know there's more content. When a video is selected the link will go from a grey color to an activate bold purple color. On the right, all the corresponding emails a user receives.
                        </Text>
                    </Box>
                    <Text as="h3" variant="body" fontWeight="bold" fontSize={1} mt={3} mb={2}>
                        Video page final design
                    </Text>
                    <Box 
                        mt={1} 
                        maxWidth={9}
                    >
                        <Image 
                            src={img[5]} 
                            alt="Video page final design" 
                        />
                    </Box>
                    <Box mt={4}>
                        <Text 
                            as="h2" 
                            variant="body"
                            measureNarrow 
                            fontSize={4} 
                            fontWeight="bold"  
                            lineHeight="title" 
                            mb={2}
                        >
                            Designing the home page
                        </Text>
                        <Text as="p" variant="body" measureWide>
                            I wanted the home page to keep in line with the same mantra, simple & clean. I started off with cards showing the product's logo but I felt this took attention away from the content. It felt unnecessary.
                        </Text>
                        <Text as="h3" variant="body" fontWeight="bold" fontSize={1} mt={3} mb={2}>
                            Home page iteration-1
                        </Text>
                        <Box 
                            mt={1} 
                            maxWidth={9}
                        >
                            <Image 
                                src={img[6]} 
                                alt="Home page iteration part one"
                            />
                        </Box>
                        <Text as="h3" variant="body" fontWeight="bold" fontSize={1} mt={3} mb={2}>
                            Home page iteration-2
                        </Text>
                        <Box 
                            mt={1} 
                            maxWidth={9}
                        >
                            <Image 
                                src={img[7]} 
                                alt="Home page iteration part one"
                            />
                        </Box>
                        <Text as="p" variant="body" marginTop={3} measureWide>
                            The final design leaves room for additional content. It transitions into a card when hovered. Towards the top, I give a brief description of the project. Here I also wanted to break the mold a bit with the layout. I made the title big with a short description on the side. I looked at <LinkAnchor variant="default" href="http://khaospublishing.com/product/merged-contours-schick-toikka/" target="blank">
                            Schick Toikka - Merged Contours
                            </LinkAnchor> for layout inspirations.
                        </Text>
                        <Text as="h3" variant="body" fontWeight="bold" fontSize={1} mt={3} mb={2}>
                            Layout inspiration-1
                        </Text>
                        <Box 
                            mt={1} 
                            maxWidth={9}
                        >
                            <Image 
                                src={img[8]} 
                                alt="Layout inspiration part one"
                            />
                        </Box>
                        <Text as="h3" variant="body" fontWeight="bold" fontSize={1} mt={3} mb={2}>
                            Layout inspiration-2
                        </Text>
                        <Box 
                            mt={1} 
                            maxWidth={9}
                        >
                            <Image 
                                src={img[9]} 
                                alt="Layout inspiration part two"
                            />
                        </Box>
                        <Text as="h3" variant="body" fontWeight="bold" fontSize={1} mt={3} mb={2}>
                            Home page final design
                        </Text>
                        <Box 
                            mt={1} 
                            maxWidth={9}
                        >
                            <Image 
                                src={img[10]} 
                                alt="Home page final design"
                            />
                        </Box>
                    </Box>
                    <Box mt={4}>
                        <Text 
                            as="h2" 
                            variant="body"
                            measureNarrow 
                            fontSize={4} 
                            fontWeight="bold"  
                            lineHeight="title" 
                            mb={2}
                        >
                            Animations
                        </Text>
                        <Text as="p" variant="body" measureWide>
                            I'm a fan of just the right amount of animations. In other words, not using animations just because we can. Instead, using animations because they enhance the user experience. I wanted the entire card section to stand out when a user hovered over it. So I added a transition into a card with a slight light purple drop shadow. When a user clicks on the card I wanted a smooth transition into the next page. For this I made the title transition with the user. You'll notice the title goes from it's original large font-size into a smaller font-size.
                        </Text>
                        <Text as="h4" variant="body" fontWeight="bold" mt={3} mb={1}>
                            Title transition
                        </Text>
                        <Box 
                            mt={2}
                            maxWidth="1024px"
                        >
                            <div style={{position: 'relative', paddingBottom: '62.5%', height: '0'}}><iframe title="Onboarding experience" src="https://www.loom.com/embed/f9706137348e4f1680b978f1b9af5ed7" frameBorder="0" webkitallowfullscreen="true" style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}}></iframe></div>
                        </Box>
                        <Text as="p" variant="body" marginTop={3} measureWide>
                            When scaling the home page down to mobile the header title and content would crunch up next to each other. I wanted to keep the large title effect but still give the content room to breath. So, when on mobile, the title now shows up as a subtle backdrop. This helps bring the main content to the
                        </Text>
                        <Text as="h4" variant="body" fontWeight="bold" mt={3} mb={1}>
                            Responsiveness
                        </Text>
                        <Box 
                            mt={2}
                            maxWidth="1024px"
                        >
                            <div style={{position: 'relative', paddingBottom: '62.5%', height: '0'}}><iframe title="Onboarding experience" src="https://www.loom.com/embed/274803e08b3b4349b1ffa3245bf4666f" frameBorder="0" webkitallowfullscreen="true" style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}}></iframe></div>
                        </Box>
                        <Text as="p" variant="body" marginTop={3} measureWide>
                            I wanted a way for the user to know which video they were viewing from the list. For this I added an active and disable state to each video link. When a video is active the user will see the link turn from a grey color to bold purple color. When a user hovers over the circle navigation it will slowly pop out with a drop shadow and filling the arrow icon with the active purple color. I gave the arrow icon for our text link buttons a subtle bounce effect. This way, when hovered, it indicates to the user another page will be opened.
                        </Text>
                        <Text as="h4" variant="body" fontWeight="bold" mt={3} mb={1}>
                            Active links and navigation
                        </Text>
                        <Box 
                            mt={2}
                            maxWidth="1024px"
                        >
                            <div style={{position: 'relative', paddingBottom: '62.5%', height: '0'}}><iframe title="Active links and navigation" src="https://www.loom.com/embed/0a699bfe47c441749718042b65e1dfc0" frameBorder="0" webkitallowfullscreen="true" style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}}></iframe></div>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        );
    }
}

export default antiFragile;