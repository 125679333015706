import React from 'react';

const IconRightArrow = (props) => {
    const width = props.width
    const height = props.height
    const fill = props.fill
    const stroke = props.stroke
    const title = props.name

    return (
        <svg width={width} height={height} viewBox="0 0 18 18" fill={fill} aria-labelledby={title} xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 8.625H16.4995" stroke={stroke} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.875 3L16.4998 8.62482L10.875 14.2496" stroke={stroke} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
};

export default IconRightArrow;