import React from 'react';
// import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import Box from '../../Box/Box';
import Text from '../../Text/Text';

import { StyledCardBox } from './style';

const CardPreview = (props) => {
    const {title, body, url} = props.data;

    return (
        <React.Fragment>
            <StyledCardBox href={url}>
                <Text 
                    variant="body"
                    as="h1"
                    fontSize={4}
                    fontWeight="bold"
                    color="green.0"
                >
                    {title[0]}
                </Text>
                <Box mt={2}>
                    <Text measureWide>
                        {body[0]}
                    </Text>
                </Box>
                <Box mt={2}>
                    <Text as="span" variant="caption">{props.buttonTitle}</Text>
                    <svg width="18px" height="18px" viewBox="0 0 18 18">
                        <path 
                            d="M1.5 8.625H16.4995" 
                            stroke="black" strokeWidth="2" 
                            strokeMiterlimit="10" 
                            strokeLinecap="round" 
                            strokeLinejoin="round"
                        />
                        <path 
                            d="M10.875 3L16.4998 8.62482L10.875 14.2496" 
                            stroke="black" 
                            strokeWidth="2" 
                            strokeMiterlimit="10" 
                            strokeLinecap="round" 
                            strokeLinejoin="round"
                        />
                    </svg>
                </Box>
            </StyledCardBox>
        </React.Fragment>
    );
}

export default CardPreview;