import React from 'react';

import {StyledLinkAnchor} from './style';

const LinkAnchor = (props) => {
    return (
        <React.Fragment>
            <StyledLinkAnchor {...props} />
        </React.Fragment>
    );
}

export default LinkAnchor;