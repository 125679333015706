import React, { Component } from 'react';
import CardPreview from '../../components/Cards/CardPreview/CardPreview';
import Nav from '../../components/Nav/Nav';
import Box from '../../components/Box/Box';
import posts from '../../sample-posts';

class MyList extends Component {
    state = {
        posts: posts
    }

    render() {
        return (
            <React.Fragment>
                <Box pt={3} px={[2, 2, 5]} pb={5}>
                    <Box>
                        <Nav />
                    </Box>
                    <Box as="section" mt={3}>
                        {Object.keys(this.state.posts).map((key) => (
                            <Box as="article" mt={2} ml={-3} key={key}>
                                <CardPreview 
                                    key={key}
                                    data={this.state.posts[key]}
                                    buttonTitle="Read More"
                                />
                            </Box>
                        ))}
                    </Box>
                </Box>
            </React.Fragment>
        );
    }
}

export default MyList;