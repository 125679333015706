import styled from 'styled-components';
import {space, color, layout, border, position} from 'styled-system';

export const StyledBox = styled.div`
    ${space}
    ${color}
    ${layout}
    ${border}
    ${position}
    max-width: ${ 
        props => props.measureNarrow ? props.theme.measure.measureNarrow :
        props => props.measure ? props.theme.measure.measure :
        props => props.measureWide ? props.theme.measure.measureWide :
        null 
    };
`;