
import React from 'react';
import { StyledImage } from './style';

const Image = (props) => {
    return (
        <React.Fragment>
            <StyledImage {...props}></StyledImage>
        </React.Fragment>
    );
}

export default Image;