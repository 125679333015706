import React from 'react';
import {StyledText} from './style';

const Text = (props) => {
    return (
        <React.Fragment>
            <StyledText {...props} />
        </React.Fragment>
    );
}

export default Text;