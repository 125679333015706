//NPM imports
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

//Relative path imports
import Home from '../../containers/Home/Home';
import MyWork from '../../containers/MyWork/MyWork';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import mphPage from '../../containers/Pages/mph/mphPage';
import parkStreet from '../../containers/Pages/parkstreet/parkStreet';
import antiFragile from '../../containers/Pages/antifragile/antiFragile';
import waldanWatches from '../../containers/Pages/waldanWatches/waldanWatches';
import digital from '../../containers/Pages/digital/digital';
import cleanit from '../../containers/Pages/cleanit/cleanit';
import obvio from '../../containers/Pages/obvio/obvio';
import monster from '../../containers/Pages/monster/monsterPage';
import remesh from '../../containers/Pages/remesh/remeshPage';
import password from '../../containers/Pages/password/password';
import passwordRemesh from '../../containers/Pages/password/passwordRemesh';

const Router = () => {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Switch>
                <Route exact path="/" component={Home}></Route>
                <Route exact path="/mywork" component={MyWork}></Route>
                <Route exact path="/mph" component={mphPage}></Route>
                <Route exact path="/parkstreet" component={parkStreet}></Route>
                <Route exact path="/antifragile" component={antiFragile}></Route>
                <Route exact path="/waldanwatches" component={waldanWatches}></Route>
                <Route exact path="/digital" component={digital}></Route>
                <Route exact path="/cleanit" component={cleanit}></Route>
                <Route exact path="/obvio" component={obvio}></Route>
                <Route exact path="/monster-energy" component={monster}></Route>
                <Route exact path="/remesh" component={remesh}></Route>
                <Route exact path="/secure" component={password}></Route>
                <Route exact path="/secureRemesh" component={passwordRemesh}></Route>
            </Switch>
        </BrowserRouter>
    )
}

export default Router;