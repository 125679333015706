//mph
import styleGuideImg from './img/mph/Creating-a-style-guide.png';
import onBoardingV1 from './img/mph/High-level-flow-on-boarding-v1.png';
import wireframeSignUpOne from './img/mph/Sign-up-and-log-in-wireframe-1.png';
import wireframeSignUpTwo from './img/mph/Sign-up-and-log-in-wireframe-2.png';
import userFirstTimeBooking from './img/mph/High level user flow-booking a car-v1.png';
import userFirstTimeBookingWireframe from './img/mph/Wireframe-users-first-time-booking-a-car.png';
import explorePage from './img/mph/Explore-page.png';
import carDetailsPage from './img/mph/Car-details-page.png';
import checkoutVerification from './img/mph/Checkout-verification-steps-not-completed.png';
import checkoutAllVerified from './img/mph/Checkout-all-verified.png';
import firstTimeListing from './img/mph/Highl level user flow - user first time listing a car - v1.png';
import wireframeListingYourCar from './img/mph/Wireframe-listing-your-car-1.png';
import wireframeListingYourCarPartTwo from './img/mph/Wireframe-listing-your-car-2.png';
import listingYourCarPeaceOfMind from './img/mph/Listing-your-car-peace-of-mind.png';
import listingYourCarProgress from './img/mph/Listing-your-car-progress.png';

//ParkStreet
import salesInvoiceReportBefore from './img/parkstreet/Sales-invoice-report-before.png';
import salesInvoiceReportAfter from './img/parkstreet/Sales-invoice-report-after.png';
import salesInvoiceReportAfterSearchState from './img/parkstreet/Sales-invoice-report-after-searching-state.png';
import salesInvoiceReportViewDetails from './img/parkstreet/Sales-invoice-report-view-details-before.png';
import salesInvoiceReportAfterDetails from './img/parkstreet/Sales-invoice-report-after-view-details.png';
import arBefore from './img/parkstreet/AR-before.png';
import arAfter from './img/parkstreet/AR-after.png';
import PSDesignSystem from './img/parkstreet/PS-Design-System.png';

//Antifragile UI/UX
import inspirationOne from './img/antifragile/Inspiration-1.png';
import inspirationTwo from './img/antifragile/Inspiration-2.png';
import inspirationThree from './img/antifragile/Inspiration-3.png';
import inspirationFour from './img/antifragile/Inspiration-4.png';
import inspirationFive from './img/antifragile/Inspiration-5.png';
import watchingAVideo from './img/antifragile/Watching-a-video.png';
import iterationOne from './img/antifragile/Iteration-1.png';
import iterationTwo from './img/antifragile/Iteration-2.png';
import schick from './img/antifragile/Schick-Toikka-Merged-Contours-1.png';
import schickTwo from './img/antifragile/Schick-Toikka-Merged-Contours-2.png';
import homePageFinal from './img/antifragile/Home-page-final-design.png';

//Waldan Watches
import wHero from './img/waldanWatches/w-hero-1900x965.png';
import wNewArrivals from './img/waldanWatches/w-new-arrivals-1900x965.png';
import wNewArrivalsPartTwo from './img/waldanWatches/w-new-arrivals-two-1900x965.png';
import strapFeature from './img/waldanWatches/w-strap-feature-1900x965.png';
import strapFeaturePartTwo from './img/waldanWatches/w-strap-feature-two-1900x965.png';

//Digital
import digitalLogo from './img/digital/Digital-logo.png';
import inspirationMedium from './img/digital/Inspiration-medium.png';
import inspirationStripe from './img/digital/Inspiration-stripe.png';
import designIterationOne from './img/digital/Design-iterations-1.png';
import designIterationTwo from './img/digital/Design-iterations-2.png';
import designIterationThree from './img/digital/Design-iterations-3.png';

//Cleanit
import cleanitLogo from './img/cleanit/Cleanit-logo-hero-1900x491.svg';
import cleanitLogoIterations from './img/cleanit/Cleanit-logo-iterations-1900x965.png';
import cleanitLogoIterationsPartTwo from './img/cleanit/Cleanit-logo-iterations-two-1900x965.png';
import cleanitLogoIterationsPartThree from './img/cleanit/Cleanit-logo-iterations-three-1900x965.png';
import cleanitHomePage from './img/cleanit/Home-page.png';

//Obvio
import obvioLogo from './img/obvio/OBVIO-logo.png';
import inspiration from './img/obvio/Inspiration.png';
import iteration from './img/obvio/Iteration.png';
import iterationPartTwo from './img/obvio/Iteration-2.png';

//Monster Energy
import dashboardBefore from './img/monster/Before-chain-funding-dashboard.png';
import dashboardAfterPartOne from './img/monster/After-dashboard-part-one.png';
import navigationBefore from './img/monster/Before-navigation.png';
import dashboardDiscountsBefore from './img/monster/Before-discounts-dashboard.png';
import dashboardDiscountsAfter from './img/monster/After-discounts-dashboard.png';

const posts = {
  parkStreet: {
    title: [['Park Street'], ['My role']],
    img: [
      [salesInvoiceReportBefore],
      [salesInvoiceReportAfter],
      [salesInvoiceReportAfterSearchState],
      [salesInvoiceReportViewDetails],
      [salesInvoiceReportAfterDetails],
      [arBefore],
      [arAfter],
      [PSDesignSystem]
    ],
    url: '/parkstreet',
    body: [
      [
        'Park Street provides alcoholic beverage companies in the U.S. and around the world software to manage their inventory, accounting, capital, customer service, and logistics. I was hired as their UI/UX Designer to help reduce CSS bloat, minimize design inconsistencies, and work with product owners to create new features.'
      ],

      ['Copy']
    ]
  },

  remesh: {
    title: [['Remesh']],
    url: '/secureRemesh',
    img: [
      [dashboardBefore],
      [dashboardAfterPartOne],
      [navigationBefore],
      [dashboardDiscountsBefore],
      [dashboardDiscountsAfter]
    ],
    body: [
      [
        'Remesh allows organizations to have a live conversation with their target audience at scale, using AI to analyze and organize responses in real-time. I was hired as their Senior Product Designer to work on the core live conversation experience. Here I partnered with engineers, data scientists, PMs, and other designers to find opportunities, test and launch features.'
      ]
    ]
  },

  monster: {
    title: [['Monster Energy']],
    url: '/secure',
    img: [
      [dashboardBefore],
      [dashboardAfterPartOne],
      [navigationBefore],
      [dashboardDiscountsBefore],
      [dashboardDiscountsAfter]
    ],
    body: [
      [
        'Monster Energy is one of the top-selling energy drink companies in the world, worth more than $32 Billion. One of the problems they faced was they outgrew their excel spreadsheet process and legacy app experience (internally known as their sales and promotions tool) that tracked all their store partnerships, discounts, and inventory. I worked alongside their team to help redesign this tool.'
      ]
    ]
  },

  mph: {
    title: [
      ['mph'],
      ['My role'],
      ['Creating a design system that can be used across both iOS and Web'],
      ['Designing the sign up and log in experience for iOS'],
      ['High level user flow'],
      ['Wireframes'],
      ['UI/UX'],
      ['On-boarding experience - prototype'],
      ['Sign up with error - prototype']
    ],
    url: '/mph',
    img: [
      [styleGuideImg],
      [onBoardingV1],
      [wireframeSignUpOne],
      [wireframeSignUpTwo],
      [userFirstTimeBooking],
      [userFirstTimeBookingWireframe],
      [explorePage],
      [carDetailsPage],
      [checkoutVerification],
      [checkoutAllVerified],
      [firstTimeListing],
      [wireframeListingYourCar],
      [wireframeListingYourCarPartTwo],
      [listingYourCarPeaceOfMind],
      [listingYourCarProgress]
    ],
    body: [
      [
        'mph is one of the most popular exotic car rental companies in Florida. They set out to create a startup that would compete in the peer-to-peer car rental market place. Here I worked as the Lead Product Designer creating high-level user flows, wireframes, prototypes, and design systems for both their iOS and Web platform. Helped support front-end engineering by creating styled-components with React for our UI implementations.'
      ],

      [
        "One of the goals was to build out a native iOS app and a responsive web application in parallel. We couldn't afford to design for each platform in a silo. This meant coming up with a design system that can be used for both platforms while still keeping in mind all the best practices and native behaviors for each platform."
      ],

      [
        "In this user flow, we break down the sign up and login flow into its corresponding actions. From verifying a phone number to helping a user out when they can't figure out their password. Even more important, we make sure to design for any errors the user may encounter during this process. We're using Amazon Cognito which comes with standard developer savvy error messages. We wanted to translate as many of those into plain English for our users to quickly understand what is happening and move forward."
      ],

      [
        "I didn't spend too much time wireframing this flow. I already had a few patterns and design elements I had established on previous flows that I wanted to incorporate. Instead, I quickly mocked up a few screens to help give me a slight nudge in the right direction."
      ],

      [
        "We wanted to really simplify the very first screen a user sees right after the app launches. You can simply either sign up or log in. But, at the same time, we wanted to give the user some context as to what exactly we offer. Three simple pillars to the product - you can rent a car from hundreds of local hosts, you're automatically insured, and or you can make money by listing your car."
      ],

      [
        'Using Framer X, I enhanced the on-boarding experience from a simple image to a background video with content scrolling horizontally. The video itself is to be determined. We eventually want to use a video that showcases briefly the whole booking and listing experience.'
      ],

      [
        `Throughout the rest of the flow, whether you sign up or log in, all the screens are very much straight forward. Using white space to help separate elements, and hints of colors to call out any tertiary actions - for example, "Forgot your password".`
      ],

      [
        "When there's an error a subtle slider appears from the bottom up pointing out the error along with the field it corresponds to."
      ]
    ]
  },

  waldanWatches: {
    title: [['Waldan Watches']],
    img: [
      [wHero],
      [wNewArrivals],
      [wNewArrivalsPartTwo],
      [strapFeature],
      [strapFeaturePartTwo]
    ],
    url: '/waldanwatches',
    body: [
      [
        'Waldan Watches was a project I worked on while under MCO, a creative agency in Miami, FL. I was contracted to help build out an e-commerce store using WordPress as the CMS, converting PSD designs into front-end code, and integrating a few third party services.'
      ]
    ]
  },

  antiFragile: {
    title: [['Antifragile UI/UX'], ['My role']],
    img: [
      [inspirationOne],
      [inspirationTwo],
      [inspirationThree],
      [inspirationFour],
      [inspirationFive],
      [watchingAVideo],
      [iterationOne],
      [iterationTwo],
      [schick],
      [schickTwo],
      [homePageFinal]
    ],
    url: '/antifragile',
    body: [
      [
        "Antifragile UI/UX is a platform focused on showing screen recordings of how digital products adapt and respond to errors in a user's flow. I used React with Styled-Components for all my front-end development, Amazon S3 to serve all my images, Wista to host my videos, and Now to host the site. I have the project up on Github."
      ]
    ]
  },

  digital: {
    title: [['Digital']],
    img: [
      [digitalLogo],
      [inspirationMedium],
      [inspirationStripe],
      [designIterationOne],
      [designIterationTwo],
      [designIterationThree]
    ],
    url: '/digital',
    body: [
      [
        'Digital was a logo I created for what was to be a print magazine. The magazine was to be based on taking old interesting articles on tech & design and bringing them to the world of print.'
      ]
    ]
  },

  cleanit: {
    title: [['Cleanit']],
    img: [
      [cleanitLogo],
      [cleanitLogoIterations],
      [cleanitLogoIterationsPartTwo],
      [cleanitLogoIterationsPartThree],
      [cleanitHomePage]
    ],
    url: '/cleanit',
    body: [
      [
        "Cleanit was a client project in which I helped with creating the logo and landing page. The client not only wanted to express the company's purpose on the landing page but also wanted the option to immediately allow users to start using the product."
      ]
    ]
  },

  obvio: {
    title: [['OBVIO']],
    img: [[obvioLogo], [inspiration], [iteration], [iterationPartTwo]],
    url: '/obvio',
    body: [
      [
        'I incorporated a small independent studio under OBVIO Studio Inc. for any freelancing work that may come my way. And of course was thrilled to come up with a logo for it.'
      ]
    ]
  }
};

export default posts;
