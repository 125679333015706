import React, { Component } from 'react';
import posts from '../../../sample-posts';
import Nav from '../../../components/Nav/Nav';
import Box from '../../../components/Box/Box';
import Text from '../../../components/Text/Text';
import LinkAnchor from '../../../components/Links/LinkAnchor';
import Flex from '../../../components/Flex/Flex';
import Image from '../../../components/Image/Image';

class mphPage extends Component {
    state = {
        posts: posts,
        isActive: true
    }

    render() {
        const {title, body, img} = this.state.posts.mph;

        return (
            <React.Fragment>
                <Box pt={3} px={[2, 2, 5]} mb={5}>
                    <Box>
                        <Nav active={this.state.isActive} />
                    </Box>  
                    <Text
                        as="h1"
                        variant="body"
                        fontSize={6}
                        fontWeight="bold"
                        color="green.0"
                        my={4}
                    >
                        {title[0]}
                    </Text>
                    <Box measureWide>
                        <Text as="h2" variant="body" fontSize={4} fontWeight="bold" mb={2}>{title[1]}</Text>
                        <Text as="p" variant="body">
                            {body[0]}
                        </Text>
                    </Box>
                    <Box mt={4}>
                        <Text 
                            as="h2" 
                            variant="body"
                            measureNarrow 
                            fontSize={4} 
                            fontWeight="bold"  
                            lineHeight="title" 
                            mb={2}
                        >
                                {title[2]}
                        </Text>
                        <Text as="p" variant="body" measureWide>
                            {body[1]}
                        </Text>
                        <Box mt={3} maxWidth={9}>
                            <img src={img[0]} alt="style guide" />
                        </Box>
                    </Box>
                    <Box mt={4}>
                        <Text 
                            as="h2" 
                            variant="body"
                            measureNarrow 
                            fontSize={4} 
                            fontWeight="bold"  
                            lineHeight="title" 
                            mb={2}
                        >
                                {title[3]}
                        </Text>
                        <Text as="p" variant="body" measureWide>
                            {body[2]}
                        </Text>
                        <Text as="h3" variant="body" fontWeight="bold" mt={3} mb={1}>
                            {title[4]}
                        </Text>
                        <Flex>
                            <LinkAnchor variant="default" href={img[1]} download>
                                Download
                            </LinkAnchor>
                            <Text ml={1}>
                                to get a closer look.
                            </Text>
                        </Flex>
                        <Box 
                            mt={3} 
                            maxWidth={9}
                        >
                            <LinkAnchor variant="default" href={img[1]} download>
                                <Image 
                                    src={img[1]} 
                                    alt="High level user flow sign up & login" 
                                    border="1px solid"
                                    borderColor="grey.3"
                                />
                            </LinkAnchor>
                        </Box>
                        <Box mt={4}>
                            <Text 
                                as="h3" 
                                variant="body"
                                measureNarrow 
                                fontSize={3} 
                                fontWeight="bold"  
                                lineHeight="title" 
                                mb={2}
                            >
                                    {title[5]}
                            </Text>
                            <Text as="p" variant="body" measureWide>
                                {body[3]}
                            </Text>
                            <Box 
                                mt={3} 
                                maxWidth={9}
                            >
                                <Image 
                                    src={img[2]} 
                                    alt="Sign up and login wireframe part 1" 
                                />
                            </Box>
                            <Box 
                                mt={3} 
                                maxWidth={9}
                            >
                                <Image 
                                    src={img[3]} 
                                    alt="Sign up and login wireframe part 2" 
                                />
                            </Box>
                        </Box>
                        <Box mt={4}>
                            <Text 
                                as="h3" 
                                variant="body"
                                measureNarrow 
                                fontSize={3} 
                                fontWeight="bold"  
                                lineHeight="title" 
                                mb={2}
                            >
                                    {title[6]}
                            </Text>
                            <Text as="p" variant="body" measureWide>
                                {body[4]}
                            </Text>
                            <Text as="p" variant="body" marginTop={2} measureWide>
                                {body[5]}
                            </Text>
                            <Text as="h4" variant="body" fontWeight="bold" mt={2} mb={1}>
                                {title[7]}
                            </Text>
                            <Box 
                                mt={2} 
                                maxWidth="400px"
                            >
                                <div style={{position: 'relative', paddingBottom: '187.79342723004694%', height: '0'}}><iframe title="Onboarding experience" src="https://www.loom.com/embed/721f449d56d147c09148906616565188" frameBorder="0" webkitallowfullscreen="true" style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}}></iframe></div>
                            </Box>
                            <Text as="p" variant="body" marginTop={3} measureWide>
                                {body[7]}
                            </Text>
                            <Text as="h4" variant="body" fontWeight="bold" mt={2} mb={1}>
                                {title[8]}
                            </Text>
                            <Box 
                                mt={2} 
                                maxWidth="400px"
                            >
                                <div style={{position: 'relative', paddingBottom: '187.79342723004694%', height: '0'}}><iframe title="Onboarding experience" src="https://www.loom.com/embed/09fb25f8164746c191f49b3c5697cc07" frameBorder="0" webkitallowfullscreen="true" style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}}></iframe></div>
                            </Box>
                        </Box>
                    </Box>
                    <Box mt={4}>
                        <Text 
                            as="h2" 
                            variant="body"
                            measureNarrow 
                            fontSize={4} 
                            fontWeight="bold"  
                            lineHeight="title" 
                            mb={2}
                        >
                                Designing the car booking experience for iOS and Web
                        </Text>
                        <Text as="p" variant="body" measureWide>
                            The booking experience is broken down into four sections — <i>explore page, search results, car details, and checkout.</i> Each of these sections is then broken down into smaller sub-sections of actions a user can take — which is what made this user flow an interesting challenge.
                        </Text>
                        <Text as="h3" variant="body" fontWeight="bold" mt={3} mb={1}>
                            High level user flow
                        </Text>
                        <Flex>
                            <LinkAnchor variant="default" href={img[4]} download>
                                Download
                            </LinkAnchor>
                            <Text ml={1}>
                                to get a closer look.
                            </Text>
                        </Flex>
                        <Box 
                            mt={3} 
                            maxWidth={9}
                        >
                            <LinkAnchor variant="default" href={img[4]} download>
                                <Image 
                                    src={img[4]} 
                                    alt="High level user flow" 
                                    border="1px solid"
                                    borderColor="grey.3"
                                />
                            </LinkAnchor>
                        </Box>
                        <Text as="h4" variant="body" fontWeight="bold" mt={3} mb={1}>
                            Wireframes
                        </Text>
                        <Box 
                            mt={3} 
                            maxWidth={9}
                        >
                            <Image 
                                src={img[5]} 
                                alt="Wireframe users first time booking a car" 
                            />
                        </Box>
                        <Box mt={4}>
                            <Text 
                                as="h3" 
                                variant="body"
                                measureNarrow 
                                fontSize={3} 
                                fontWeight="bold"  
                                lineHeight="title" 
                                mb={2}
                            >
                                UI/UX
                            </Text>
                            <Text as="p" variant="body" measureWide>
                                For the explore page we wanted to keep the search action front and center with a clear message as to what you can do on this screen, "Search and rent a car from hundreds of local hosts". We divided the page into four sections highlighting the type of cars on our platform. Towards the end, we provide the user with a secondary action "List your car".
                            </Text>
                            <Text as="h4" variant="body" fontWeight="bold" mt={2} mb={1}>
                                Explore page
                            </Text>
                            <Box 
                                mt={2} 
                                maxWidth="400px"
                            >
                                <Image 
                                    src={img[6]} 
                                    alt="Explore page"
                                    border="1px solid"
                                    borderColor="grey.3"
                                />
                            </Box>
                            <Text as="p" variant="body" marginTop={3} measureWide>
                                When a user searches for a car they'll be able to see their results with a more advanced search bar. Here you can refine your dates, or filter through specific options.
                            </Text>
                            <Text as="p" variant="body" marginTop={2} measureWide>
                                We also wanted the search bar to get out of the user's way when not in use. As a user scrolls down their feed the bar slides up and out of view. It comes back down once a user starts scrolling back up. Again, we still keep the secondary action of listing your car towards the end.
                            </Text>
                            <Text as="h4" variant="body" fontWeight="bold" mt={2} mb={1}>
                                Search results - prototype
                            </Text>
                            <Box 
                                mt={2} 
                                maxWidth="400px"
                            >
                                <div style={{position: 'relative', paddingBottom: '187.79342723004694%', height: '0'}}><iframe title="Onboarding experience" src="https://www.loom.com/embed/16a8e6dd35ec4d10ac1a0b0287dd61ad" frameBorder="0" webkitallowfullscreen="true" style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}}></iframe></div>
                            </Box>
                            <Text as="p" variant="body" marginTop={3} measureWide>
                                When a user clicks on a specific car they'll be taken to that car's details page. Here a user will be able to see a more granular breakdown — miles included, a brief description, features, past trip reviews, car owner information, guidelines, the ability to report the listing, other additional cars the user might like, and the price per day. The price per day always stays within the user's sight while scrolling.
                            </Text>
                            <Text as="h4" variant="body" fontWeight="bold" mt={2} mb={1}>
                                Car details page
                            </Text>
                            <Box 
                                mt={2} 
                                maxWidth="400px"
                            >
                                <Image 
                                    src={img[7]} 
                                    alt="Explore page"
                                    border="1px solid"
                                    borderColor="grey.3"
                                />
                            </Box>
                            <Text as="p" variant="body" marginTop={3} measureWide>
                                When a user decides to move forward with the price they'll be taken to a checkout screen. Here you get a summary of what you're about to confirm and pay for. The two most important themes on this screen are the full price breakdown, and the verification steps before a user can rent a car. Once the user completes all the verification steps their primary button now displays "Request to book this car". From here on out the user has to wait for the host to approve the booking. Typically the host has 8 hours to respond back.
                            </Text>
                            <Text as="h4" variant="body" fontWeight="bold" mt={2} mb={1}>
                                Checkout
                            </Text>
                            <Box 
                                mt={2} 
                                maxWidth="400px"
                            >
                                <Image 
                                    src={img[8]} 
                                    alt="Explore page"
                                    border="1px solid"
                                    borderColor="grey.3"
                                />
                            </Box>
                            <Text as="h4" variant="body" fontWeight="bold" mt={3} mb={1}>
                                Checkout - all verified
                            </Text>
                            <Box 
                                mt={2} 
                                maxWidth="400px"
                            >
                                <Image 
                                    src={img[9]} 
                                    alt="Explore page"
                                    border="1px solid"
                                    borderColor="grey.3"
                                />
                            </Box>
                            <Text as="h4" variant="body" fontWeight="bold" mt={3} mb={1}>
                                Booking a car - prototype
                            </Text>
                            <Box 
                                mt={2} 
                                maxWidth="400px"
                            >
                                <div style={{position: 'relative', paddingBottom: '187.79342723004694%', height: '0'}}><iframe title="Onboarding experience" src="https://www.loom.com/embed/01a487e0b1a14abeacbd724e6eeb1a83" frameBorder="0" webkitallowfullscreen="true" style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}}></iframe></div>
                            </Box>
                        </Box>
                    </Box>
                    <Box mt={4}>
                        <Text 
                            as="h2" 
                            variant="body"
                            measureNarrow 
                            fontSize={4} 
                            fontWeight="bold"  
                            lineHeight="title" 
                            mb={2}
                        >
                                Designing the car listing experience for iOS
                        </Text>
                        <Text as="p" variant="body" measureWide>
                            The car listing experience was a bit challenging because we had to take this long process and try to make it as seamless as possible to the user. We definitely wanted to avoid long drawn out forms.
                        </Text>
                        <Text as="p" variant="body" marginTop={2} measureWide>
                            In the user flow we ask for location of the car, the type of car your listing, verify that we accept that type of car, verify your identity—through the use of phone verification, license verification, entering any additional car information, taking a profile picture, pricing, reviewing & accepting our terms of service, and publishing your listing. With all of this of course, we had to take into account any errors a user may encounter.
                        </Text>
                        <Text as="h3" variant="body" fontWeight="bold" mt={3} mb={1}>
                            High level user flow
                        </Text>
                        <Flex>
                            <LinkAnchor variant="default" href={img[10]} download>
                                Download
                            </LinkAnchor>
                            <Text ml={1}>
                                to get a closer look.
                            </Text>
                        </Flex>
                        <Box 
                            mt={3} 
                            maxWidth={9}
                        >
                            <LinkAnchor variant="default" href={img[10]} download>
                                <Image 
                                    src={img[10]} 
                                    alt="high level user flow" 
                                    border="1px solid"
                                    borderColor="grey.3"
                                />
                            </LinkAnchor>
                        </Box>
                        <Text as="h4" variant="body" fontWeight="bold" mt={3} mb={1}>
                            Wireframes
                        </Text>
                        <Box 
                            mt={2} 
                            maxWidth={9}
                        >
                            <Image 
                                src={img[11]} 
                                alt="Wireframe listing your car part 1" 
                            />
                        </Box>
                        <Box 
                            mt={2} 
                            maxWidth={9}
                        >
                            <Image 
                                src={img[12]} 
                                alt="Wireframe listing your car part 1" 
                            />
                        </Box>
                        <Box mt={4}>
                            <Text 
                                as="h3" 
                                variant="body"
                                measureNarrow 
                                fontSize={3} 
                                fontWeight="bold"  
                                lineHeight="title" 
                                mb={2}
                            >
                                UI/UX
                            </Text>
                            <Text as="p" variant="body" measureWide>
                                The challenge for "listing your car" flow was how long it was. We're asking for all this information but at the same time promising that it wouldn't take more than just a few minutes. To address this we broke down the process into smaller chunks and provided the user only a few questions and actions to take at once.
                            </Text>
                            <Text as="p" variant="body" marginTop={2} measureWide>
                                Before the user even gets started we provide a few "peace of mind" slides. Hopefully, this helps nudge the user into moving forward by addressing any doubts they may have and showing the benefits of listing with us.
                            </Text>
                            <Box 
                                mt={2} 
                                maxWidth={9}
                            >
                                <Image 
                                    src={img[13]} 
                                    alt="Listing-your-car-peace-of-mind"
                                />
                            </Box>
                            <Text as="p" variant="body" measureWide marginTop={3}>
                                We break the rest of the process into three main action steps — check to see if your car is eligible, I.D. verification, and Last step. Once a user completes each of these steps they'll see a green checkmark. As the user is filling out specific actions and questions they'll see a progress bar above their navigation tab. Again, the goal with these design elements is to help reduce a user's anxiety and stress of filling out forms. This way the user always knows where they are in the process.
                            </Text>
                            <Box 
                                mt={2} 
                                maxWidth={9}
                            >
                                <Image 
                                    src={img[14]} 
                                    alt="Listing-your-car-progress"
                                />
                            </Box>
                            <Text as="h4" variant="body" fontWeight="bold" mt={3} mb={1}>
                                Listing your car - prototype
                            </Text>
                            <Box 
                                mt={2} 
                                maxWidth="400px"
                            >
                                <div style={{position: 'relative', paddingBottom: '187.79342723004694%', height: '0'}}><iframe title="Onboarding experience" src="https://www.loom.com/embed/ce4dc9bc465545ec8bf41b61fd806630" frameBorder="0" webkitallowfullscreen="true" style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}}></iframe></div>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        );
    }
}

export default mphPage;