//Colors
const white = [
    '#fff' //0
]

const black = [
    '#111111' //0
]

const purple = [
    '#9455D5' //0
]

const green = [
    '#1EC06A' //0
]

const blue = [
    '#0064d9', //0
    '#012e86' //1
]

const grey = [
    '#777777', //0
    '#999999', //1
    '#CCCCCC', //2
    '#DDDDDD', //3
    '#EDEDED', //4
    '#FAFAFA'  //5
]

const red = [
    '#ef0000', //0
]

const colors = {
    white,
    black,
    purple,
    green,
    blue,
    grey,
    red
}

//Font Family
const fonts = {
    body: '"Inter", sans-serif;',
}

//Typescale 1 (14px), 2 (16px), 3 (20px), 4 (24px), 5 (36px), 6 (48px), 7 (80px), 8 (97px), 9 (112px), 10 (128px)
const fontSizes = ['_', 0.875, 1, 1.25, 1.5, 2.25, 3, 5, 6, 7, 8, 9].map(n => n + 'rem');

//Font Weights
const fontWeights = {
    normal: 400,
    semiBold: 600,
    bold: 700,
};

//Line Height
const lineHeights = {
    solid: 1,
    title: 1.25,
    copy: 1.5,
};

const measure = {
    measureNarrow: '20em',
    measure: '30em',
    measureWide: '34em'

}

//Space 0 (0px), 1 (4px), 2 (16px), 3 (32px), 4 (64px), 5 (128px), 6 (256px), 7 (512px)
const space = [0, .25, 1, 2, 4, 8, 16, 32].map(n => n + 'rem');

//Size 1 (8px), 2 (16px), 3 (32), 4 (64px), 5 (128px), 6 (256px), 7 (512px), 8 (768px), 9 (1024px), 10 (1536px)
const sizes = ['_', .5, 1, 2, 4, 8, 16, 32, 48, 64, 96].map(n => n + 'rem');

// const sizes = [8, 16, 32, 64, 128, 256, 512, 768, 1024, 1536];

const theme = {
    colors,
    fonts,
    fontSizes,
    fontWeights,
    lineHeights,
    measure,
    space,
    sizes
}

export default theme;