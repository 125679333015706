import styled from 'styled-components';

export const StyledCardBox = styled.a`
    text-decoration: none;
    color: unset;
    display: block;
    transition: all 0.2s ease-in-out 0s;
    padding: ${props => props.theme.space[3]};
    max-width: 600px;
    width: 100%;

    span {
        background-image: linear-gradient(0deg, var(--line, #111111) 0%, var(--line, #111111) 100%);
        background-position: 100% 100%;
        background-repeat: no-repeat;
        background-size: var(--background-size, 100%) 2px;
        transition: background-size .2s linear var(--background-delay, .15s);
        line-height: 21px;
        transform: translateZ(0);
        display: inline-block;
    }

    svg {
        vertical-align: sub;
        display: inline;
        line-height: 18px;
        position: relative;
        left: 8px;
        fill: none;
        stroke-dasharray: 16 30;
        stroke-dashoffset: var(--stroke-dashoffset, 16);  
        transition: stroke-dashoffset var(--stroke-duration, .15s) var(--stroke-easing, linear) var(--stroke-delay, 0s);
    }

    :hover {
        transform: translateY(-3px);
        box-shadow: rgba(209, 196, 255, 0.5) 0px 20px 40px;
        transition: all 0.2s ease-in-out 0s;
        border-radius: 8px;
        --background-size: 0%;
        --background-delay: 0s;
        --stroke-dashoffset: 0;
        --stroke-duration: .3s;
        --stroke-easing: cubic-bezier(.3, 1.5, .5, 1);
        --stroke-delay: .195s;
    }
`;