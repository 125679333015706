import React, { Component } from 'react';
import posts from '../../../sample-posts';
import Nav from '../../../components/Nav/Nav';
import Box from '../../../components/Box/Box';
import Text from '../../../components/Text/Text';
import LinkAnchor from '../../../components/Links/LinkAnchor';
import Image from '../../../components/Image/Image';

class digital extends Component {
    state = {
        posts: posts,
        isActive: true
    }

    render() {
        const {img} = this.state.posts.digital;

        return (
            <React.Fragment>
                <Box pt={3} px={[2, 2, 5]} mb={5}>
                  <Box>
                    <Nav active={this.state.isActive} />
                  </Box>  
                  <Text
                    as="h1"
                    variant="body"
                    fontSize={6}
                    fontWeight="bold"
                    color="green.0"
                    mt={4}
                  >
                    Digital
                  </Text>
                  <Box 
                    my={3} 
                    maxWidth={9}
                  >
                    <Image 
                      src={img[0]} 
                      alt="Digital logo" 
                    />
                  </Box>
                  <Box measureWide>
                    <Text as="h2" variant="body" fontSize={4} fontWeight="bold" mb={2}>
                        Project overview
                    </Text>
                    <Text as="p" variant="body" style={{display: 'inline'}}>
                      Digital was a logo I created for what was to be a print magazine. The magazine was to be based on taking old interesting articles on tech & design and bringing them to the world of print. The purpose for this was to show that things written 10 or 25 years ago can be as relevant, if not more, today.
                    </Text>
                  </Box>
                  <Box mt={4}>
                    <Text 
                        as="h2" 
                        variant="body"
                        measureNarrow 
                        fontSize={4} 
                        fontWeight="bold"  
                        lineHeight="title" 
                        mb={2}
                    >
                      Inspiration
                    </Text>
                    <Text as="p" variant="body" measureWide>
                      I was looking for something to be a cross between new age design and old school print design. I’ve always really loved Medium’s old school logo, just a simple M.
                    </Text>
                    <Text as="p" variant="body" marginTop={2} measureWide>
                      I wanted to somehow combine that with Digital. I originally thought about the title Digital Print, with Digital being a different futuristic font and Print just being a classic font.
                    </Text>
                    <Box 
                      mt={3} 
                      maxWidth={9}
                    >
                      <Image 
                        src={img[1]} 
                        alt="Medium logo" 
                      />
                    </Box>
                    <Text as="p" variant="body" marginTop={3} measureWide>
                      The color scheme I had in mind were a lot of shades of blue, purple, and green. I wasn’t looking for brightness in particular but did want a subtle pop. Neon seemed liked a great fit.
                    </Text>
                    <Text as="p" variant="body" marginTop={2} measureWide>
                      I landed across Stripe’s web page and immediately fell in love with the colors they used on their header section. I started playing around with their color palette, with the help of <LinkAnchor variant="default" href="http://sipapp.io/" target="blank"> Sip</LinkAnchor>.
                    </Text>
                    <Box
                      mt={3}
                      maxWidth={9}
                    >
                      <Image
                        src={img[2]}
                        alt="Stripe landing page example"
                      />
                    </Box>
                  </Box>
                  <Box mt={4}>
                    <Text 
                      as="h2" 
                      variant="body"
                      measureNarrow 
                      fontSize={4} 
                      fontWeight="bold"  
                      lineHeight="title" 
                      mb={2}
                    >
                      Design Iterations
                    </Text>
                    <Text as="p" variant="body" measureWide>
                      I started off by putting pencil to paper. These are the only sketches I made. A lot squares, as you can tell, but that was on purpose.
                    </Text>
                    <Text as="p" variant="body" marginTop={2} measureWide>
                      I was trying to envision a digital display on like an old school casio watch. But, with a slight twist. I wanted parts of the letters to be sort of cutoff at certain points to give it sharp look. I was definitely not interested in smooth edges.
                    </Text>
                    <Box
                      mt={3}
                      maxWidth={9}
                    >
                      <Image
                        src={img[3]}
                        alt="Design iteration part one"
                      />
                    </Box>
                    <Text as="p" variant="body" marginTop={3} measureWide>
                      Here are a few iterations with the words “Digital” and “Print” together. Again, the goal was to somehow get two different fonts to work together well.
                    </Text>
                    <Text as="p" variant="body" marginTop={2} measureWide>
                      One more with more of a futuristic look and the other with more of a classic look. Unfortunately it did not vibe well, at least I wasn’t satisfied.
                    </Text>
                    <Box
                      mt={3}
                      maxWidth={9}
                    >
                      <Image
                        src={img[4]}
                        alt="Design iteration part two"
                        border="1px solid"
                        borderColor="grey.3"
                      />
                    </Box>
                    <Text as="p" variant="body" marginTop={3} measureWide>
                      Getting the all the different shades of color together took a combination of smaller different shapes, along with the use of the fade-in tool, and the opacity tool. Here’s an example of all the shapes taken apart.
                    </Text>
                    <Box
                      mt={3}
                      maxWidth={9}
                    >
                      <Image
                        src={img[5]}
                        alt="Design iteration part three"
                        border="1px solid"
                        borderColor="grey.3"
                      />
                    </Box>
                  </Box>
                </Box>
            </React.Fragment>
        );
    }
}

export default digital;