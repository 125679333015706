import styled from 'styled-components';
import {variant, space, layout} from 'styled-system';
import theme from '../../theme';

const defaultButtonStyles = {
    color: theme.colors.purple[0],
}

const buttonVariants = variant({
    variants: {
        tertiary: {
            ...defaultButtonStyles,
        },
        tertiarySmall: {
            fontSize: theme.fontSizes[1],
            textTransform: 'uppercase',
            letterSpacing: '1px',
            fontWeight: 'bold',
            textDecoration: 'underline',
            color: theme.colors.black[0],
            ':hover': {
                color: theme.colors.purple[0]
            }
        },
        primary: {
            color: theme.colors.white[0],
            backgroundColor: theme.colors.purple[0],
            fontSize: theme.fontSizes[1],
            fontWeight: 'semiBold',
            textTransform: 'uppercase',
            minWidth: '200px',
            height: '40px',
            paddingTop: 0,
            paddingBottom: 0,
            borderRadius: '4px',
            cursor: 'pointer',
            transition: 'all 0.2s ease 0s',
            boxShadow: '0px 3px 10px rgba(22, 23, 26, 0.15)',
            letterSpacing: '0.2em',
            '&:hover': {
                transform: 'translate3d(0px, -1px, 0px)',
                boxShadow: '0px 7px 18px rgba(22, 23, 26, 0.15)'
            },
            '&:focus': {
                outlineWidth: '0px',
                transform: 'translate3d(0px, -1px, 0px)',
                boxShadow: '0px 7px 18px rgba(22, 23, 26, 0.15)'
            }
        }

    }
});

export const StyledButton = styled.button`
    ${buttonVariants}
    ${space}
    ${layout}
    transition: color .2s ease-in-out;
`;