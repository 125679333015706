import React from 'react';
import { StyledInput } from './style';

const Input = (props) => {
  return (
    <React.Fragment>
      <StyledInput {...props} />
    </React.Fragment>
  );
}

export default Input;