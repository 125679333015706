import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import NavLink from '../../../components/Links/NavLink';
import posts from '../../../sample-posts';
import Box from '../../../components/Box/Box';
import Text from '../../../components/Text/Text';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Buttons/Button';
import Flex from '../../../components/Flex/Flex';
import SVGIcon from '../../../components/Icons/index';

const encryptMyPassword = btoa(process.env.REACT_APP_PASSWORD); // change password here

class password extends Component {
    state = {
        posts: posts,
        isActive: true,
        password: "",
        showError: false
    }

    handlePassword = e => {
        this.setState({ password: e.target.value, showError: false })
    }

    handleSubmit = () => {
        const encryptPassword = btoa(this.state.password);
        if (encryptPassword === encryptMyPassword) {
            this.props.history.push('/monster-energy', {loggedIn: true});
        } else {
            this.setState({ showError: true })
        }
    }

    render() {
        return (
            <React.Fragment>
                <Flex 
                    pt={3} 
                    px={[2, 2, 5]} 
                    mb={5}
                    marginTop={3}
                    flexDirection={["column", "row", "row"]}
                >  
                    <SVGIcon 
                        width={["64px", "48px", "48px"]}
                        height={["64px", "48px", "48px"]}
                        alignSelf={["center", "unset", "unset"]}
                        marginBottom={[3, 0, 0]}
                        name="icon-lock"
                    />
                    <Flex flexDirection="column" marginLeft={[0, 2, 2]}>
                        <Text
                            as="p"
                            variant="body"
                            measure
                        >
                            Thanks for taking the time to visit my portfolio. Due to an NDA, this content is password protected. You can <NavLink variant="nav1" fontWeight="bold"><Link to={{pathname: "mailto:dan.cardenas@hey.com?subject=Portfolio password request"}} target="_blank">email</Link></NavLink> me to request a password. 
                        </Text>
                        <Box maxWidth={["100%", "200px", "200px"]} marginTop={3} position="relative">
                            {
                                this.state.showError && 
                                <Text as="p" color="red.0" fontSize={1} position="absolute" top="-25px">Invalid Password</Text>
                            }
                            <Input onChange={this.handlePassword} type="password" name="password" placeholder="Password" value={this.state.password} showError={this.state.showError} />
                            <Button minWidth={["100%", "200px", "200px"]} mt={2} variant="primary" onClick={this.handleSubmit} type="submit">Continue</Button>
                        </Box>
                    </Flex>
                </Flex>
            </React.Fragment>
        );
    }
}

export default password;