import styled from 'styled-components';
import { variant, typography } from 'styled-system';
import theme from '../../theme';

const defaultLinkStyles = {
    color: theme.colors.purple[0],
    textDecoration: 'none'
}

const linkVariants = variant({
    variants: {
        default: {
            fontSize: 2,
            fontWeight: "semiBold",
            color: theme.colors.blue[0],
            '&:hover': {
                color: theme.colors.blue[1]
            },
            '&:active': {
                color: theme.colors.blue[0]
            }
        },
        nav1: {
            ...defaultLinkStyles,
        }
    }
});

export const StyledLink = styled.span`
    a {
        ${linkVariants}
        ${typography}
    }
`;

export const StyledLinkAnchor = styled.a`
    ${linkVariants}
    color: ${props => theme.colors.blue[0]} !important;
    text-decoration: underline !important;

    :hover {
        color: ${props => theme.colors.blue[1]} !important;
    }

    :active {
        color: ${props => theme.colors.blue[0]} !important;
    }
`;