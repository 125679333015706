import styled from 'styled-components';


export const StyledInput = styled.input`
  -webkit-appearance: none;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  box-shadow: none;
  box-sizing: border-box;
  border-radius: ${props => props.theme.space[1]};
  border-style: none;
  border: 1px solid ${props => props.showError ? props.theme.colors.red[0] : props.theme.colors.grey[2]};
  padding-top: ${props => props.theme.space[0]};
  padding-bottom: ${props => props.theme.space[0]};
  padding-left: ${props => props.theme.space[2]};
  padding-right: ${props => props.theme.space[0]};
  margin: 0px auto;

  :focus {
    outline-color: ${props => props.theme.colors.purple[0]}
  }
`;