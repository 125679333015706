import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Text from '../../components/Text/Text';
import NavLink from '../../components/Links/NavLink';
import Box from '../../components/Box/Box';
import Nav from '../../components/Nav/Nav';

class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <Nav />
        <Box p={[2, 2, 5]}>
          <div>
            <Text
              variant="body"
              fontSize={5}
              fontWeight="bold"
              lineHeight="title"
              measureNarrow
            >
              Hi my name is Daniel Cardenas, you can call me Danny.
            </Text>
          </div>
          <div>
            <Text variant="body" fontSize={3} mt={3} measureWide>
              I'm a designer with product, front-end, and prototyping
              experience. My focus is on user experience, interface design,
              spinning up new concepts, and prototyping new products from
              scratch. I'm truly thankful to be able to do what I love for a
              living.
            </Text>
            <Text variant="body" fontSize={3} mt={2} measureWide>
              Check out some of{' '}
              <NavLink variant="nav1" fontWeight="bold">
                <Link to="/mywork">my work</Link>
              </NavLink>
              .{' '}
              <NavLink variant="nav1" fontWeight="bold">
                Breaking down top SaaS designs at{' '}
                <Link
                  to={{ pathname: 'https://obviostudio.substack.com/' }}
                  target="blank"
                >
                  OBVIO Studio Newsletter
                </Link>
                .{' '}
              </NavLink>{' '}
              Connect with me on{' '}
              <NavLink variant="nav1" fontWeight="bold">
                <Link
                  to={{ pathname: 'https://twitter.com/dan_inmotion' }}
                  target="blank"
                >
                  X
                </Link>
              </NavLink>
              .
            </Text>
          </div>
        </Box>
      </React.Fragment>
    );
  }
}

export default Home;
