import React from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom'; 
import Text from '../Text/Text';

const Nav = (props) => {
    const { location, active } = props;

    if (location.pathname === '/') {
        return null;
    } else {
        return (
            <nav aria-labelledby="primary-navigation">
                <ul style={{display: 'flex'}}>
                    <Text as="li" mr={3} variant="caption"><NavLink exact to="/">Home</NavLink></Text>
                    <Text as="li" mr={3} variant="caption" active={active}><NavLink to="/mywork">My Work</NavLink></Text>
                    {/* <Text as="li" variant="caption"><NavLink to="/library">Library</NavLink></Text> */}
                </ul>
            </nav>
        );
    }
}

export default withRouter(Nav);