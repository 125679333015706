import React from 'react';

import { StyledLink } from './style';

const NavLink = (props) => {
    return (
        <StyledLink href={props.href} {...props}>{props.children}</StyledLink>
    );
}

export default NavLink;