import React, { Component } from 'react';
import posts from '../../../sample-posts';
import Nav from '../../../components/Nav/Nav';
import Box from '../../../components/Box/Box';
import Text from '../../../components/Text/Text';
import LinkAnchor from '../../../components/Links/LinkAnchor';
import Image from '../../../components/Image/Image';

class waldanWatches extends Component {
    state = {
        posts: posts,
        isActive: true
    }

    render() {
        const {img} = this.state.posts.waldanWatches;

        return (
            <React.Fragment>
                <Box pt={3} px={[2, 2, 5]} mb={5}>
                  <Box>
                    <Nav active={this.state.isActive} />
                  </Box>  
                  <Text
                    as="h1"
                    variant="body"
                    fontSize={6}
                    fontWeight="bold"
                    color="green.0"
                    my={4}
                  >
                    Waldan Watches
                  </Text>
                  <Box measureWide>
                    <Text as="h2" variant="body" fontSize={4} fontWeight="bold" mb={2}>
                        Project overview
                    </Text>
                    <Text as="p" variant="body" style={{display: 'inline'}}>
                      Waldan Watches was a project I worked on while under MCO, a creative agency in Miami, FL. I was contracted to help build out an e-commerce store using WordPress as the CMS, converting PSD designs into front-end code, and integrating a few third party services.
                    </Text>
                    <Text as="p" variant="body" marginTop={2}>
                      As a team we would have multiple meetings with the CEO of Waldan Watches, Andrew Waldan, to hear out his concerns. The most important concern he expressed to us was making sure these high valued watches were secured when shipped out. This meant integrating a third-party insurance API that would work simultaneously with the checkout process.
                    </Text>
                  </Box>
                  <Box mt={4}>
                    <Text 
                        as="h2" 
                        variant="body"
                        measureNarrow 
                        fontSize={4} 
                        fontWeight="bold"  
                        lineHeight="title" 
                        mb={2}
                    >
                      Web Development
                    </Text>
                    <Text as="p" variant="body" measureWide>
                      Our team was taking some amazing photographs of the product and filming a few lifestyle videos. We wanted to be able to showcase some of those beautiful shots.
                    </Text>
                    <Text as="p" variant="body" marginTop={2} measureWide>
                      As soon as you land on the page you are greeted with a beautiful watch image and a call to action to discover more of that particular time piece.
                    </Text>
                    <Box 
                      mt={3} 
                      maxWidth={9}
                    >
                      <Image 
                        src={img[0]} 
                        alt="Watch hero" 
                      />
                    </Box>
                    <Text as="p" variant="body" marginTop={3} measureWide>
                      It was important to the client for new products not to get lost in the site. Which is why we decided to create a section in the home page that would showcase new arrivals. Since there were so many different watch categories I decided to place them in a slider. When you hover over each watch the image is then enlarged with the corresponding watch name.
                    </Text>
                    <Box
                      mt={3}
                      maxWidth={9}
                    >
                      <Image
                        src={img[1]}
                        alt="New arrivals"
                      />
                    </Box>
                    <Box
                      mt={3}
                      maxWidth={9}
                    >
                      <Image
                        src={img[2]}
                        alt="New arrivals part two"
                      />
                    </Box>
                    <Text as="p" variant="body" marginTop={3} measureWide>
                      A feature request that was a must for the shop was the ability for customers to customize their watch strap's color before placing an order.
                    </Text>
                    <Text as="p" variant="body" marginTop={2} measureWide>
                      This was probably one of the most tedious features to implement. In order to make sure all watches each had 16 different strap color variations I had to spend a lot of time in Affinity designer. I would take each watch picture and strap picture taken in the studio and start editing them to fit well with each other. I had to make sure I was as precise as possible. If not the strap color customization wouldn't transition well and look too fake to the user. It would cause a slight glitch and jump in the image.
                    </Text>
                    <Box
                      mt={3}
                      maxWidth={9}
                    >
                      <Image
                        src={img[3]}
                        alt="Strap feature"
                      />
                    </Box>
                    <Box
                      mt={3}
                      maxWidth={9}
                    >
                      <Image
                        src={img[4]}
                        alt="Strap feature part two"
                      />
                    </Box>
                  </Box>
                  <Box mt={4}>
                    <Text 
                      as="h2" 
                      variant="body"
                      measureNarrow 
                      fontSize={4} 
                      fontWeight="bold"  
                      lineHeight="title" 
                      mb={2}
                    >
                      Integrating a third party API
                    </Text>
                    <Text as="p" variant="body" measureWide>
                      One of the most important concerns addressed to us was the need for some sort of backend process that would add insurance to each watch when an order was placed. Each watch can range from $5,000 to $25,000. Fortunately I was able to find the solution with <LinkAnchor variant="default" href="https://www.riskified.com/" target="blank">Riskified</LinkAnchor>.
                    </Text>
                    <Text as="p" variant="body" marginTop={2} measureWide>
                      Riskified helps avoid and protect against fraud on high cost items sold online. At that moment there was no plugin to use in WordPress. I had to use their API documentation and integrate it with Stripe, which was the payment system setup for the shop.
                    </Text>
                  </Box>
                  <Box mt={4}>
                    <Text 
                      as="h2" 
                      variant="body"
                      measureNarrow 
                      fontSize={4} 
                      fontWeight="bold"  
                      lineHeight="title" 
                      mb={2}
                    >
                      What I learned?
                    </Text>
                    <Text as="p" variant="body" measureWide>
                      WordPress and visual composer are two powerful tools. Initially I wasn't too familiar with WordPress and didn't want to use it on this project. But the client was already familiar with the platform and expressed his need to use it.
                    </Text>
                    <Text as="p" variant="body" marginTop={2} measureWide>
                      I stumbled on visual composer and was pretty impressed by it's capabilities to recreate the PSD designs without having to start from scratch and using PHP. Plus, it was really well documented.
                    </Text>
                    <Text as="p" variant="body" marginTop={2} measureWide>
                      Since we were on a tight deadline, I was able to use visual composer to build out the frontend and created a child theme in order to use some custom javascript and css code that would get this project across the finish line before it's launch date.
                    </Text>
                  </Box>
                </Box>
            </React.Fragment>
        );
    }
}

export default waldanWatches;