import styled from 'styled-components';
import {variant, typography, space, color, position} from 'styled-system';
import theme from '../../theme';

const defaultextStyles = {
    fontFamily: 'body',
    fontWeight: 'normal',
    lineHeight: 'copy',
    color: theme.colors.black[0],
    mt: 0,
    mb: 0
};

const textVariants = variant({
    variants: {
        body: {
            ...defaultextStyles,
            fontSize: 2,
        },
        caption: {
            ...defaultextStyles,
            fontSize: 1,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            letterSpacing: '1px',
            textDecoration: 'none'
        },
        captionUnderline: {
            ...defaultextStyles,
            fontSize: 1,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            letterSpacing: '1px',
            textDecoration: 'underline',
            color: theme.colors.black[0],
            ':hover': {
                color: theme.colors.purple[0]
            }
        }
    }
});

export const StyledText = styled.p`
    ${textVariants}
    ${typography}
    ${space}
    ${color}
    ${position}
    transition: color .2s ease-in-out;
    max-width: ${ 
        props => props.measureNarrow ? props.theme.measure.measureNarrow :
        props => props.measure ? props.theme.measure.measure :
        props => props.measureWide ? props.theme.measure.measureWide :
        null 
    };

    a { 
        transition: color .2s ease-in-out;
        text-decoration: none;
        color: ${props => props.active ? props.theme.colors.purple[0] : props.theme.colors.black[0]};

        &.active {
            color: ${props => theme.colors.purple[0]};
        }

        :hover {
            color: ${props => theme.colors.purple[0]};
        }

        :active {
            color: ${props => theme.colors.black[0]};
        }
    }
`;

