import React, { Component } from 'react';
import posts from '../../../sample-posts';
import Nav from '../../../components/Nav/Nav';
import Box from '../../../components/Box/Box';
import Text from '../../../components/Text/Text';
import Image from '../../../components/Image/Image';

class obvio extends Component {
    state = {
        posts: posts,
        isActive: true
    }

    render() {
        const {img} = this.state.posts.obvio;

        return (
            <React.Fragment>
                <Box pt={3} px={[2, 2, 5]} mb={5}>
                  <Box>
                    <Nav active={this.state.isActive} />
                  </Box>  
                  <Text
                    as="h1"
                    variant="body"
                    fontSize={6}
                    fontWeight="bold"
                    color="green.0"
                    mt={4}
                  >
                    OBVIO
                  </Text>
                  <Box 
                    my={3} 
                    maxWidth={8}
                  >
                    <Image 
                      src={img[0]} 
                      alt="OBVIO logo" 
                    />
                  </Box>
                  <Box measureWide>
                    <Text as="h2" variant="body" fontSize={4} fontWeight="bold" mb={2}>
                        Project overview
                    </Text>
                    <Text as="p" variant="body" style={{display: 'inline'}}>
                      I incorporated a small independent studio under OBVIO Studio Inc. for any freelancing work that may come my way. And of course was thrilled to come up with a logo for it. Above is the logo I designed and below is a breakdown of what went into designing the logo.
                    </Text>
                  </Box>
                  <Box mt={4}>
                    <Text 
                      as="h2" 
                      variant="body"
                      measureNarrow 
                      fontSize={4} 
                      fontWeight="bold"  
                      lineHeight="title" 
                      mb={2}
                    >
                      Inspiration
                    </Text>
                    <Text as="p" variant="body" measureWide>
                      From the very beginning I knew I wanted a bold typeface. Something that would make a strong statement.
                    </Text>
                    <Text as="p" variant="body" marginTop={2} measureWide>
                      I looked at a few other designs for inspiration and narrowed them down to 3 of my favorites; Dustin Curtis’ Svbtle.com logo, Noah Stoke’s hellobold.com logo, and North Technologies’ logo.
                    </Text>
                    <Box 
                      mt={3} 
                      maxWidth={9}
                    >
                      <Image 
                        src={img[1]} 
                        alt="Obvio inspiration examples" 
                      />
                    </Box>
                  </Box>
                  <Box mt={4}>
                    <Text 
                      as="h2" 
                      variant="body"
                      measureNarrow 
                      fontSize={4} 
                      fontWeight="bold"  
                      lineHeight="title" 
                      mb={2}
                    >
                      Design Iterations
                    </Text>
                    <Text as="p" variant="body" measureWide>
                      Here are a few design iterations. Arial Black with a bold weight worked really well for what I was looking for.
                    </Text>
                    <Text as="p" variant="body" marginTop={2} measureWide>
                      Color here was pretty simple. I already knew coming into creating the logo that I wanted a black and white color scheme.
                    </Text>
                    <Box
                      mt={3}
                      maxWidth={9}
                    >
                      <Image
                        src={img[2]}
                        border="1px solid"
                        borderColor="grey.3"
                        alt="Obvio design iteration"
                      />
                    </Box>
                    <Text as="p" variant="body" marginTop={3} measureWide>
                      I was also looking to have a symbol that can play well with social media avatars. So I decided I can take the “O” in Obvious and make that a standalone logo as well.
                    </Text>
                    <Text as="p" variant="body" marginTop={2} measureWide>
                      At first I started with a thin lightning bolt, but quickly noticed that it was a mismatch with the rest of the heavier weight elements.
                    </Text>
                    <Text as="p" variant="body" marginTop={2} measureWide>
                      Sidenote: I originally wanted to name the company Obvious, but I went with OBVIO. I dig it.
                    </Text>
                    <Box
                      mt={3}
                      maxWidth={9}
                    >
                      <Image
                        src={img[3]}
                        border="1px solid"
                        borderColor="grey.3"
                        alt="Obvio design iteration"
                      />
                    </Box>
                  </Box>
                </Box>
            </React.Fragment>
        );
    }
}

export default obvio;