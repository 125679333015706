import React from 'react';

import { StyledButton } from './style';

const Button = (props) => {
    return (
        <React.Fragment>
            <StyledButton {...props} />
        </React.Fragment>
    );
}

export default Button;