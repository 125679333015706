import React from 'react';
import Router from './components/Router/Router';
import Nav from './components/Nav/Nav';
import { ThemeProvider } from 'styled-components';
import theme from './theme';

function App() {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Router>
          <Nav />
        </Router>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
