import React, { Component } from 'react';
import posts from '../../../sample-posts';
import Nav from '../../../components/Nav/Nav';
import Box from '../../../components/Box/Box';
import Text from '../../../components/Text/Text';
import LinkAnchor from '../../../components/Links/LinkAnchor';
import Image from '../../../components/Image/Image';

class parkStreet extends Component {
  state = {
    posts: posts,
    isActive: true
  };

  render() {
    const { img } = this.state.posts.parkStreet;

    return (
      <React.Fragment>
        <Box pt={3} px={[2, 2, 5]} mb={5}>
          <Box>
            <Nav active={this.state.isActive} />
          </Box>
          <Text
            as="h1"
            variant="body"
            fontSize={6}
            fontWeight="bold"
            color="green.0"
            my={4}
          >
            Park Street
          </Text>
          <Box measureWide>
            <Text as="h2" variant="body" fontSize={4} fontWeight="bold" mb={2}>
              My role
            </Text>
            <Text as="p" variant="body" style={{ display: 'inline' }}>
              <LinkAnchor
                variant="default"
                href="https://www.parkstreet.com/"
                target="blank"
              >
                Park Street
              </LinkAnchor>{' '}
              provides alcoholic beverage companies in the U.S. and around the
              world software to manage their inventory, accounting, capital,
              customer service, and logistics. I was hired as their UI/UX
              Designer to help reduce CSS bloat, minimize design
              inconsistencies, and work with product owners to create new
              features.
            </Text>
            <Text as="p" variant="body" marginTop={2}>
              Each tool had multiple features and sub-tools. Used by internal
              employees and clients. I worked with an incredible team composed
              of backend developers, frontend developers, and product owners.
              All committed to making this new venture of design and product
              improvements work.
            </Text>
          </Box>
          <Box mt={4}>
            <Text
              as="h2"
              variant="body"
              measureNarrow
              fontSize={4}
              fontWeight="bold"
              lineHeight="title"
              mb={2}
            >
              Reducing CSS Bloat
            </Text>
            <Text as="p" variant="body" measureWide>
              When I started the team was already using Angular 1.x. Which
              presented a few roadblocks as to how we would move forward with
              our CSS architecture. We initially wanted to use Styled Components
              but that was not possible with Angular 1.x, at least, not at the
              time I was there. This is were BEM and Tachyons came into the
              picture.
            </Text>
            <Text as="p" variant="body" marginTop={2} measureWide>
              <LinkAnchor
                variant="default"
                href="https://tachyons.io/"
                target="blank"
              >
                Tachyons
              </LinkAnchor>{' '}
              is a CSS framework where each CSS class has a single purpose. It
              does one thing and one thing well. Also known as functional CSS.
              At the end of the day we're trying to avoid the cascade as much as
              possible. BEM stands for block, element, modifier. It's a naming
              methodology in CSS to help get a grip over global styles.
            </Text>
            <Text as="p" variant="body" marginTop={2} measureWide>
              Tachyons was perfect because it was just pure CSS. At that time
              the project relied heavily on an older version of Bootstrap. I
              couldn't just rip that out. At the same time, as a team, we were
              skeptical of bringing in any new CSS frameworks. We decided to
              play it by ear. I would bring in what we needed from Tachyons, as
              we needed it, and pre-fix it with additional letters just to be
              extra sure we wouldn't clash with Bootstrap. If it made more sense
              for use to use BEM to build out a component's css we would use it
              and place it in its specific angular component folder. Here's a{' '}
              <LinkAnchor
                variant="default"
                href="https://docs.google.com/presentation/d/10wj1qLnQK9BLxw-iUW7_mcrPQEQ1GD8K7fug2iy97UQ/edit#slide=id.p"
                target="blank"
              >
                presentation
              </LinkAnchor>{' '}
              I put together, with the help of our frontend team, to make the
              argument for using BEM and Tachyons to our CTO.
            </Text>
          </Box>
          <Box mt={4}>
            <Text
              as="h2"
              variant="body"
              measureNarrow
              fontSize={4}
              fontWeight="bold"
              lineHeight="title"
              mb={2}
            >
              Sales Invoice tool re-design
            </Text>
            <Text as="p" variant="body" measureWide>
              As the name suggests, this tool is used to get a heartbeat on how
              an alcoholic beverage company is performing in overall sales. The
              user can dive deeper into each invoice and get a detailed
              breakdown. The new design emphasizes total value and total cases.
              Cleans up the search section by clearing up what each filter does
              and now displays a search input. I grouped all actions that belong
              to the table itself over to the right.
            </Text>
            <Text as="p" variant="body" marginTop={2} measureWide>
              One of the main actions users take is exporting to excel. I left
              that as an obvious action and all other actions that are
              infrequently used I grouped inside the more menu (three vertical
              dots). The table itself is a lot clearer, all ordered by what's
              most important to the user. On the right-hand side of the table,
              we give the user a quick reference to the total value and total
              cases sold for that particular invoice, with an option to get a
              full breakdown by clicking on view more.
            </Text>
            <Text
              as="h3"
              variant="body"
              fontWeight="bold"
              fontSize={1}
              mt={3}
              mb={2}
              color="grey.0"
            >
              Before
            </Text>
            <Box mt={1} maxWidth={9}>
              <Image src={img[0]} alt="Sales invoice report before" />
            </Box>
            <Text
              as="h3"
              variant="body"
              fontWeight="bold"
              fontSize={1}
              mt={3}
              mb={2}
            >
              After
            </Text>
            <Box mt={1} maxWidth={9}>
              <Image src={img[1]} alt="Sales invoice report after" />
            </Box>
            <Box mt={3} maxWidth={9}>
              <Image src={img[2]} alt="Sales invoice report after" />
            </Box>
            <Text as="p" variant="body" marginTop={3} measureWide>
              And now let's compare the invoice details, which is what a user
              sees when they click "view more", or in the case of the old
              design, when they click on the plus sign to expand. With the new
              design, we kept the same concept of expanding to view more
              information. We cleaned up the table by making the table header
              more prominent and giving the data some breathing room. This helps
              the user to quickly see what category the data falls under and
              skim through it.
            </Text>
            <Text
              as="h3"
              variant="body"
              fontWeight="bold"
              fontSize={1}
              mt={3}
              mb={2}
              color="grey.0"
            >
              Before
            </Text>
            <Box mt={1} maxWidth={9}>
              <Image
                src={img[3]}
                alt="Sales invoice report view details before"
              />
            </Box>
            <Text
              as="h3"
              variant="body"
              fontWeight="bold"
              fontSize={1}
              mt={3}
              mb={2}
            >
              After
            </Text>
            <Box mt={1} maxWidth={9}>
              <Image
                src={img[4]}
                alt="Sales invoice report after view details"
              />
            </Box>
          </Box>
          <Box mt={4}>
            <Text
              as="h2"
              variant="body"
              measureNarrow
              fontSize={4}
              fontWeight="bold"
              lineHeight="title"
              mb={2}
            >
              Accounts Receivable graph re-design
            </Text>
            <Text as="p" variant="body" measureWide>
              The original Accounts receivable graph was extremely difficult for
              our users to quickly read through and understand. There was no
              direct correlation between the graph and the table data. The two
              most important pieces of information for users "what was
              overdue?", and "what was coming up due?" was difficult to quickly
              tell apart.
            </Text>
            <Text as="p" variant="body" marginTop={2} measureWide>
              With the redesign, we gave the graph a title. Kept the dollar
              amount (Balance) on the y-axis with a clear dollar sign symbol and
              condensed the days on the x-axis into four different buckets. 0-15
              days, 16-30 days, 31-45 days, and 45+ days. We differentiated
              "Total Amount Due" and Total Amount Overdue" by assigning each a
              distinct color. This color would then be used as a label to easily
              spot invoices that were "Overdue" or "Due in" in our card table
              data. Picking the right colors was another project onto itself.
            </Text>
            <Text as="p" variant="body" marginTop={2} measureWide>
              In the end, we played it safe and went with colors that people who
              use accounting tools would be familiar with. The old design used
              the brand's red color, regardless if it was overdue or coming up
              due. We used grey for "Due in" because it's a neutral color. We
              don't want to set off any alarms for invoices that were still in
              good standing. We used orange for invoices that were "Overdue"
              because it's bright enough to set off a flag but not as harsh as
              red. We wanted to set off a flag not set off a full-blown alarm.
              We wanted to leave red for drastic measures. For example, accounts
              that were at risk of being sent to collections.
            </Text>
            <Text
              as="h3"
              variant="body"
              fontWeight="bold"
              fontSize={1}
              mt={3}
              mb={2}
              color="grey.0"
            >
              Before
            </Text>
            <Box mt={1} maxWidth={9}>
              <Image src={img[5]} alt="Accounts receivable before" />
            </Box>
            <Text
              as="h3"
              variant="body"
              fontWeight="bold"
              fontSize={1}
              mt={3}
              mb={2}
            >
              After
            </Text>
            <Box mt={1} maxWidth={9}>
              <Image src={img[6]} alt="Accounts receivable after" />
            </Box>
          </Box>
          <Box mt={4}>
            <Text
              as="h2"
              variant="body"
              measureNarrow
              fontSize={4}
              fontWeight="bold"
              lineHeight="title"
              mb={2}
            >
              Design System & Overaching theme
            </Text>
            <Text as="p" variant="body" measureWide>
              I worked on a few other features that I, unfortunately, can't
              share. But, the overarching theme throughout the rest of the tools
              tries to be very similar. Easy to read text with a clearer
              hierarchy. The use of white space to create some breathing room
              between elements and minimizing random floating elements.
            </Text>
            <Text as="p" variant="body" marginTop={2} measureWide>
              The idea was to create some sort of design consistency throughout
              all the different tools. That's not to say we didn't come across
              features in which context mattered more than consistency and
              that's fine. It's in those moments where you realize your design
              system can and should be flexible to future unknowns.
            </Text>
            <Box mt={3} maxWidth={9}>
              <Image src={img[7]} alt="PS Design System" />
            </Box>
          </Box>
        </Box>
      </React.Fragment>
    );
  }
}

export default parkStreet;
