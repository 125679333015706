import React from 'react';

const IconLine = (props) => {
    const width = props.width
    const height = props.height
    const fill = props.fill
    const stroke = props.stroke
    const title = props.name
    const strokeWidth = props.strokeWidth

    return (
        <svg width={width} height={height} viewBox="0 0 94 2" fill={fill} aria-labelledby={title} xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1H93" stroke={stroke} strokeWidth={strokeWidth} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
};

export default IconLine;