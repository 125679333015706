import React, { Component } from 'react';
import posts from '../../../sample-posts';
import Nav from '../../../components/Nav/Nav';
import Box from '../../../components/Box/Box';
import Text from '../../../components/Text/Text';
// import LinkAnchor from '../../../components/Links/LinkAnchor';
// import Flex from '../../../components/Flex/Flex';
import Image from '../../../components/Image/Image';

class monsterPage extends Component {
  state = {
    posts: posts,
    isActive: true
  };

  componentDidMount() {
    const historyState = this.props.location.state;
    if (historyState && historyState.loggedIn) {
      return;
    }
    this.props.history.push('/secure');
  }

  render() {
    const { img } = this.state.posts.monster;

    return (
      <React.Fragment>
        <Box pt={3} px={[2, 2, 5]} mb={5}>
          <Box>
            <Nav active={this.state.isActive} />
          </Box>
          <Text
            as="h1"
            variant="body"
            fontSize={6}
            fontWeight="bold"
            color="green.0"
            my={4}
          >
            Monster Energy
          </Text>
          <Box measureWide>
            <Text as="h2" variant="body" fontSize={4} fontWeight="bold" mb={2}>
              My role
            </Text>
            <Text as="p" variant="body" style={{ display: 'inline' }}>
              Monster Energy is one of the top-selling energy drink companies in
              the world, worth more than $32 Billion. One of the problems they
              faced was they outgrew their excel spreadsheet process and legacy
              app experience (internally known as their sales and promotions
              tool) that tracked all their store partnerships, discounts, and
              inventory. I worked alongside their team to help redesign this
              tool.
            </Text>
          </Box>
          <Box mt={4}>
            <Text
              as="h2"
              variant="body"
              measureNarrow
              fontSize={4}
              fontWeight="bold"
              lineHeight="title"
              mb={2}
            >
              Chain Funding Dashboard redesign
            </Text>
            <Text as="p" variant="body" measureWide>
              This tool has a few different levels of functionality. I was
              tasked to only focus on the Chain Funding Dashboard and create an
              overarching theme that could be used for other pages. The tool
              itself is mainly used for forecasting, keeping track of inventory,
              and gauging performance with buyers. I added a title to the top of
              the page to help users gain context as to where they are in the
              application. The filters, search bar, and table were all sort of
              blended together. In the redesign, I created some separation by
              giving the table layout a more visual definition (e.g. striped
              rows, colored header, and an option to scroll horizontally without
              losing context of other dashboard items). I placed a bit more
              emphasis on the search input by adding a border around it and
              aligning it to the left. And, placed all filters under a dropdown.
            </Text>
            <Text as="p" variant="body" marginTop={2} measureWide>
              It was important for stakeholders to easily see what account
              orders were open, pending, approved, or denied. I added a labeling
              system to be able to quickly point those out. The search query
              would sometimes run hundreds of items. This would dramatically
              slow things down and frustrate the user because the list would
              seem never-ending. In the redesign, I added pagination to be able
              to limit the number of items listed. This helped speed page load
              times and give the user context as to how long the search results
              were.
            </Text>
            <Text
              as="h3"
              variant="body"
              fontWeight="bold"
              fontSize={1}
              mt={3}
              mb={2}
              color="grey.0"
            >
              Before
            </Text>
            <Box mt={1} maxWidth={9}>
              <Image
                src={img[0]}
                alt="Chain funding dashboard before design"
                border="1px solid"
                borderColor="grey.3"
              />
            </Box>
            <Text
              as="h3"
              variant="body"
              fontWeight="bold"
              fontSize={1}
              mt={3}
              mb={2}
            >
              After
            </Text>
            <Box mt={1} maxWidth={9}>
              <Image
                src={img[1]}
                alt="Chain funding dashboard after design part two"
                border="1px solid"
                borderColor="grey.3"
              />
            </Box>
            <Box mt={4}>
              <Text
                as="h3"
                variant="body"
                measureNarrow
                fontSize={3}
                fontWeight="bold"
                lineHeight="title"
                mb={2}
              >
                Filters
              </Text>
              <Text as="p" variant="body" measureWide>
                Not only did I move all filters under a dropdown to help
                declutter the view, but I also made it more contextual. Now,
                when a user selects multiple filters they will be able to see
                exactly which filters are in use. This was important because
                there was a real possibility that more filters would be added
                and without that context, the user would get lost as to what is
                being filtered.
              </Text>
              <Box mt={3} maxWidth={9}>
                <div
                  style={{
                    position: 'relative',
                    paddingBottom: '62.5%',
                    height: '0'
                  }}
                >
                  <iframe
                    title="Onboarding experience"
                    src="https://www.loom.com/embed/42ac4cbe8bbe4190a2cd23f105774ac7"
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%'
                    }}
                  ></iframe>
                </div>
              </Box>
            </Box>
            <Box mt={4}>
              <Text
                as="h3"
                variant="body"
                measureNarrow
                fontSize={3}
                fontWeight="bold"
                lineHeight="title"
                mb={2}
              >
                Navigation
              </Text>
              <Text as="p" variant="body" measureWide>
                Navigating through the different tools and sub-pages was a bit
                rough. You had two sidebars displaying the main sidebar and a
                sub-sidebar. This would take up a lot of real estate on the left
                side and made it difficult to tell which page you were on. In
                the redesign, I leveraged the existing hamburger menu on top
                which would slide open and display the main tools--Chain
                Funding, Activity Report, Store List, Reports, Category
                Management, Authorizations, and User. I added an active state to
                make it easier for the user to see which tool they were on.
              </Text>
              <Text as="p" variant="body" marginTop={2} measureWide>
                When a user selects the store they go into that store's
                dashboard information. They will now see a sub-sidebar with all
                the corresponding sub-pages and an option to hide the
                sub-sidebar. You no longer see the store name duplicated.
                Instead, the store name is on the top navbar and we now display
                the dashboard's corresponding title.
              </Text>
              <Text as="p" variant="body" marginTop={2} measureWide>
                On the right of the top navbar, there were too many primary
                buttons. We decided to move all primary actions down into the
                dashboard and only leave tertiary actions corresponding to store
                changes up there.
              </Text>
              <Text
                as="h3"
                variant="body"
                fontWeight="bold"
                fontSize={1}
                mt={3}
                mb={2}
                color="grey.0"
              >
                Before
              </Text>
              <Box mt={1} maxWidth={9}>
                <Image src={img[2]} alt="Before navigation" />
              </Box>
              <Text
                as="h3"
                variant="body"
                fontWeight="bold"
                fontSize={1}
                mt={3}
                mb={2}
              >
                After
              </Text>
              <Box mt={1} maxWidth={9}>
                <div
                  style={{
                    position: 'relative',
                    paddingBottom: '62.5%',
                    height: '0'
                  }}
                >
                  <iframe
                    title="Onboarding experience"
                    src="https://www.loom.com/embed/5adeaaeb57824104abbbe6537030be08"
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%'
                    }}
                  ></iframe>
                </div>
              </Box>
            </Box>
          </Box>
          <Box mt={4}>
            <Text
              as="h2"
              variant="body"
              measureNarrow
              fontSize={4}
              fontWeight="bold"
              lineHeight="title"
              mb={2}
            >
              Discounts Dashboard redesign
            </Text>
            <Text as="p" variant="body" measureWide>
              This tool is used to keep track of the different promotions and
              discounts at each store. Users weren't thrilled about selecting
              each table item one at a time. I added a select all to the table
              header to help with this. There are these card items that display
              the dates and pricing for promotions. As well as a description
              field for notes. These would take up a lot of space vertically. In
              the redesign, I placed the card items under a dropdown and hid the
              notes under a message icon. To give the user a bit more context,
              the notes icon would be disabled if there were no notes.
            </Text>
            <Text as="p" variant="body" marginTop={2} measureWide>
              The Submit Chain Alert and Add Discount buttons both had solid
              colors which made them both seem as important main actions. Add
              Discount is the main primary action, I kept it as a solid colored
              button. I gave our secondary action, Submit Chain Alert, an
              outlined colored button, and replaced the warning icon with a less
              dire icon. The warning icon didn't seem to make much sense. There
              wasn't anything the user needed to look out for. And if there was
              we could call it out before the actual action was taken, along
              with some descriptive text.
            </Text>
            <Text
              as="h3"
              variant="body"
              fontWeight="bold"
              fontSize={1}
              mt={3}
              mb={2}
              color="grey.0"
            >
              Before
            </Text>
            <Box mt={1} maxWidth={9}>
              <Image
                src={img[3]}
                alt="Before discounts dashboard"
                border="1px solid"
                borderColor="grey.3"
              />
            </Box>
            <Text
              as="h3"
              variant="body"
              fontWeight="bold"
              fontSize={1}
              mt={3}
              mb={2}
            >
              After
            </Text>
            <Box mt={1} maxWidth={9}>
              <Image
                src={img[4]}
                alt="After discounts dashboard"
                border="1px solid"
                borderColor="grey.3"
              />
            </Box>
            <Box mt={4}>
              <Text
                as="h3"
                variant="body"
                measureNarrow
                fontSize={3}
                fontWeight="bold"
                lineHeight="title"
                mb={2}
              >
                Overarching theme
              </Text>
              <Text as="p" variant="body" measureWide>
                There are a few other research items that I can't share due to a
                strict NDA. I'm currently filtering out what other designs I'm
                allowed to share and will hopefully post some of those up here
                soon. But, the overarching theme is the same. Use of white space
                to create separation between tables, inputs, and filters. Table
                pagination to reduce load speeds and users' frustrations with
                long lists. Striped table data and different colored headers to
                help make the information on the table clearer. Titled page
                headers and active state tabs to help give users context as to
                where they are in the tool.
              </Text>
            </Box>
          </Box>
        </Box>
      </React.Fragment>
    );
  }
}

export default monsterPage;
