import React, { Component } from 'react';
import posts from '../../../sample-posts';
import Nav from '../../../components/Nav/Nav';
import Box from '../../../components/Box/Box';
import Text from '../../../components/Text/Text';
import Image from '../../../components/Image/Image';

class cleanit extends Component {
    state = {
        posts: posts,
        isActive: true
    }

    render() {
        const {img} = this.state.posts.cleanit;

        return (
            <React.Fragment>
                <Box pt={3} px={[2, 2, 5]} mb={5}>
                  <Box>
                    <Nav active={this.state.isActive} />
                  </Box>  
                  <Text
                    as="h1"
                    variant="body"
                    fontSize={6}
                    fontWeight="bold"
                    color="green.0"
                    mt={4}
                  >
                    Cleanit
                  </Text>
                  <Box 
                    my={3} 
                    maxWidth={8}
                  >
                    <Image 
                      src={img[0]} 
                      alt="Cleanit logo" 
                    />
                  </Box>
                  <Box measureWide>
                    <Text as="h2" variant="body" fontSize={4} fontWeight="bold" mb={2}>
                        Project overview
                    </Text>
                    <Text as="p" variant="body" style={{display: 'inline'}}>
                      Cleanit was a client project in which I helped with creating the logo and landing page. The client not only wanted to express the company's purpose on the landing page but also wanted the option to immediately allow user to start using the product.
                    </Text>
                    <Text as="p" variant="body" marginTop={2}>
                      Cleanit was a client project in which I helped with creating the logo and landing page. The client not only wanted to express the company's purpose on the landing page but also wanted the option to immediately allow user to start using the product.
                    </Text>
                  </Box>
                  <Box mt={4}>
                    <Text 
                        as="h2" 
                        variant="body"
                        measureNarrow 
                        fontSize={4} 
                        fontWeight="bold"  
                        lineHeight="title" 
                        mb={2}
                    >
                      Design Iterations
                    </Text>
                    <Text as="p" variant="body" measureWide>
                      The client was very clear with his vision for the logo. He wanted something that would embody the company's purpose. Nothing abstract, he was looking for a loud and clear message.
                    </Text>
                    <Text as="p" variant="body" marginTop={2} measureWide>
                      I went through several design iterations and worked shoulder to shoulder with the client-even. I even encouraging him to sketch out a few things on paper. I wouldn’t normally ask that from a client but he was very enthusiastic and wanted to roll up his sleeves and get his hands dirty. Can’t say no to that!
                    </Text>
                    <Box 
                      mt={3} 
                      maxWidth={9}
                    >
                      <Image 
                        src={img[1]} 
                        alt="Cleanit logo iteration" 
                      />
                    </Box>
                    <Box 
                      mt={3} 
                      maxWidth={9}
                    >
                      <Image 
                        src={img[2]} 
                        alt="Cleanit logo iteration part two" 
                      />
                    </Box>
                    <Box 
                      mt={3} 
                      maxWidth={9}
                    >
                      <Image 
                        src={img[3]} 
                        alt="Cleanit logo iteration part three" 
                      />
                    </Box>
                  </Box>
                  <Box mt={4}>
                    <Text 
                      as="h2" 
                      variant="body"
                      measureNarrow 
                      fontSize={4} 
                      fontWeight="bold"  
                      lineHeight="title" 
                      mb={2}
                    >
                      Web Design
                    </Text>
                    <Text as="p" variant="body" measureWide>
                      I wanted to keep the landing page as simple as possible. The product itself is just a cleaning service offered through text messaging. The best way I could think of to showcase a product like this was to provide an example of a conversation between a user and the service. The goal with this example was to show how easy it is to use the service.
                    </Text>
                    <Box
                      mt={3}
                      maxWidth={9}
                    >
                      <Image
                        src={img[4]}
                        alt="Home page design"
                      />
                    </Box>
                  </Box>
                  <Box mt={4}>
                    <Text 
                      as="h2" 
                      variant="body"
                      measureNarrow 
                      fontSize={4} 
                      fontWeight="bold"  
                      lineHeight="title" 
                      mb={2}
                    >
                      What I learned?
                    </Text>
                    <Text as="p" variant="body" measureWide>
                      At first I thought a client sketching out a logo for me was silly. But it turns out that you really get a sense for what is in their head. It doesn't necessarily have to be a work of art, because mine aren't. They are called a sketch for a reason. But even some chicken scratch can move the ball forward and give you some perspective.
                    </Text>
                  </Box>
                </Box>
            </React.Fragment>
        );
    }
}

export default cleanit;