import React from 'react';
import IconRightArrow from './IconRightArrow';
import IconLine from './IconLine';
import IconLock from './IconLock';

const SVGIcon = (props) => {
    switch(props.name) {
        case "icon-right-arrow":
            return <IconRightArrow {...props} />
        case "icon-line":
            return <IconLine {...props} />
        case "icon-lock":
            return <IconLock {...props} />
        default:
            return <div></div>;
    }
};

export default SVGIcon;