import React from 'react';
import {StyledFlex} from './style';

const Flex = (props) => {
    return (
        <React.Fragment>
            <StyledFlex {...props} />
        </React.Fragment>
    );
}

export default Flex;