import React from 'react';
import { StyledIconLock } from './style';

const IconLock = (props) => {
    const width = props.width
    const height = props.height
    // const fill = props.fill
    // const stroke = props.stroke
    const title = props.name

    return (
        <StyledIconLock {...props} width={width} height={height} viewBox="0 0 48 48" fill="none" aria-labelledby={title} xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M24 46C36.1503 46 46 36.1503 46 24C46 11.8497 36.1503 2 24 2C11.8497 2 2 11.8497 2 24C2 36.1503 11.8497 46 24 46Z" fill="#ECEEED"/>
            <path d="M32 22.1428H16V32.4285H32V22.1428Z" fill="#A3A8A6" stroke="#A3A8A6" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M28.5716 22.1429V17.5714C28.5716 16.359 28.0899 15.1962 27.2326 14.3389C26.3753 13.4816 25.2126 13 24.0001 13C22.7877 13 21.625 13.4816 20.7677 14.3389C19.9103 15.1962 19.4287 16.359 19.4287 17.5714V22.1429" stroke="#A3A8A6" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </StyledIconLock>
    )
};

export default IconLock;